import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { PackagingTypeServiceService } from 'services/packaging-type-service-service';

type Model = {
  PackagingProductId: number;
  Amount: number;
  Id?: number;
};

type data = { data: ServiceConsumptionPackagingModel };

export type ServiceConsumptionPackagingModel = { PackagingProductId: number; Amount: number; Id?: number };

@autoinject
export class ServicePackagingConsumptionCreateEdit {
  @bindable
  @observable
  model: Model;
  targetModel: Model = { PackagingProductId: 0, Amount: undefined };
  modelChanged(model: Model) {
    this.targetModel = JSON.parse(JSON.stringify(model));
    this.form.PackagingProductId = this.targetModel.PackagingProductId;
    this.form.Amount = this.targetModel.Amount;
  }

  @bindable service: Models.Service;

  @bindable onSave: (data: data) => void;
  @bindable onCanceled: () => void;

  @bindable
  @observable
  protected visible = false;

  private validationController: ValidationController;

  protected form = {
    PackagingProductId: undefined,
    Amount: undefined,
  };

  constructor(
    private errorService: ErrorService,
    protected packagingTypeServiceService: PackagingTypeServiceService,
    private t: I18N,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  protected applyValidationRules(item: Model) {
    ValidationRules.ensure('PackagingProductId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('Amount')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(0)
      .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
      .on(item);
  }

  private async validate(item: Model) {
    this.applyValidationRules(item);
    this.validationController.reset();
    return (await this.validationController.validate({ object: item })).valid;
  }

  protected async getPackagingTypesService() {
    const packagingTypesService = await this.packagingTypeServiceService.getAll('?$expand=Product');
    packagingTypesService.forEach((packagingTypeService) => {
      if (packagingTypeService.Product) {
        packagingTypeService.Name = packagingTypeService.Name + ' (' + packagingTypeService.Product.ArticleNo + ')';
      }
    });

    return packagingTypesService;
  }

  private createModel() {
    const source = this.targetModel;
    const model = {
      ServiceId: this.service.Id,
      PackagingProductId: this.form.PackagingProductId,
      Amount: this.form.Amount,
    };

    if (source.Id) {
      model['Id'] = source.Id;
    }

    return model as ServiceConsumptionPackagingModel;
  }

  async save() {
    const model = this.createModel();

    if (!(await this.validate(model))) {
      return;
    }

    try {
      this.onSave?.({
        data: this.createModel(),
      });
      this.resetForm();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  resetForm() {
    this.form.PackagingProductId = 0;
    this.form.Amount = undefined;
  }

  cancel() {
    this.onCanceled?.();
  }
}
